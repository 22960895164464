import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button  } from "react-bootstrap"

import constants from '../helpers/constants';
import { navigate, Link } from 'gatsby';
import logo from '../images/Logo300x140.png'; 

import LocalStorage from '../components/localStorage';
import Fetch from '../helpers/fetch';
import { Alert } from "react-bootstrap";
import getDecodedToken from '../helpers/jwtToken';

const LoginPage = ({location}) => {
  const [userName, setUserName] = useState('');
  const [pwd, setPwd] = useState('');
  const [isSupplierLogin, setIsSupplierLogin] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const [showLoginSuccess, setShowLoginSuccess] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [newUserRegistration, setNewUserRegistration] = useState(false);

  const handleChange = (e) => {
    switch (e.target.id) {
      case 'username':
        setUserName(e.target.value);
        break;
      case 'password':
        setPwd(e.target.value); 
      break;
    }
  }

  useEffect(() =>{
    LocalStorage.removeItem('jwt','')
    if (location && location.state && location.state.passwordChange) {
      setPasswordChange(true);
    }
    if (location && location.state && location.state.newUserRegistration) {
      setNewUserRegistration(true);
    }
  },[])


  const handleSubmit = (e) => {
    let data = JSON.stringify( {username: userName, password: pwd})
    
    Fetch.post(`${constants.BACKEND_URL}/users-auth/login`, data).then((data) => {
      if (data.error) {
        setShowLoginError(true);
        return;
      }
      if (data && data.access_token) {
        setShowLoginError(false);
        setShowLoginSuccess(true);
        LocalStorage.set('jwt', data.access_token);
        if(isSupplierLogin) {
          navigate('/suppliers/prodsheet');
          return false;
        }

        if (data.firstLogin) {
          navigate('/profile/changePassword');
          return;
        }
        
        if (getDecodedToken().role === 'user') {
          navigate('/profile')
          return;
        }
        navigate('/');
      }
    })
    e.preventDefault();
  }


  return (
    <div className="invite">
      <div id="leftImage">
      </div>
      <div id="registration">
        <Container className="text-center">
          <Row className="mb-3">
            <Col><img className="logo" src={logo} alt="Logo" /></Col>
          </Row>
          <Row>
            <Col>
              {passwordChange && 
                <Alert variant="info">Password changed successfully</Alert>
              }
              {newUserRegistration && 
                <Alert variant="info">Your account has been created. Please check your email</Alert>
              }
              <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-around">
                <Form.Group>
                  <Form.Control 
                    required
                    name="username" 
                    id="username" 
                    size="lg" 
                    type="text" 
                    placeholder="Enter your username"
                    onChange={handleChange}
                    autoComplete="new-password" 
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control 
                    required
                    name="password" 
                    id="password" 
                    size="lg" 
                    type="password" 
                    placeholder="Enter your password"
                    onChange={handleChange}
                    autoComplete="new-password" 
                  />
                 
                </Form.Group>
                <Button className='mb-3' type="submit" size="lg" style={{width:"100%"}} id="login">Login</Button>
                <div className="form-group"><Link to="/customers/forgotPassword"><small>Forgot Password?</small></Link></div>
                <div className="form-group">
                  <Alert show={showLoginError} variant='danger'  >
                    Incorrect username or password
                  </Alert>
                  <Alert show={showLoginSuccess} variant="success"  >
                    Please wait while we log you in
                  </Alert>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>

  );
}

export default LoginPage